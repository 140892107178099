import { QueryPage, QueryPageResult } from './index';
import { alphaUrl, ajaxApi } from '@/utils/request';
import type { SurveyInfo } from './survey';

/** 职位类型 00：通用套餐，01：合同专属套餐，02：用户自定义套餐 */
enum PackageType {
  通用套餐 = '00',
  合同专属套餐 = '01',
  用户自定义套餐 = '02',
}

/** 套餐信息 */
export type PackageInfo = {
  /** 套餐ID */
  packageId: string
  /** 套餐名称 */
  packageName: string
  /** 套餐类型 */
  packageType: PackageType
  /** 业务id，合同专属套餐时存入客户id，用户自定义套餐时存入用户id */
  bizId: string
  /** 套餐描述 */
  description: string
  /** 套餐价格，精确到分 */
  price: string
  /** 调查项数组 */
  surveyList: SurveyInfo[];
  /** 是否选中 */
  checked?: boolean
  /** 组合餐价 */
  surveyPrice: string
  /**数量 */
  num: number
};

type PackageQueryPage = QueryPage & Partial<PackageInfo>;

interface PackageQuery {
  /** 是否需要调查工作经历，默认位false，为true会返回不含有工作经历相关调查项的套餐 */
  hasNoWork?: boolean
}

/** 获取当前用户所有可用套餐 */
export const getPackageList = (params = {} as PackageQuery) => ajaxApi('get', alphaUrl + '/pkg/query/list', params) as Promise<PackageInfo[]>;

/** 新增套餐 */
export const addPackage = (params: PackageInfo) => ajaxApi('post', alphaUrl + '/pkg/add', params);

/** 删除套餐 */
export const removePackage = (id: PackageInfo['packageId']) =>
  ajaxApi('post', alphaUrl + '/pkg/add', { packageId: id });

/** 根据id获取套餐信息 */
export const getPackageInfo = (id: PackageInfo['packageId']) =>
  ajaxApi('get', alphaUrl + '/pkg/query/id', { packageId: id }) as Promise<PackageInfo>;

/** 修改套餐信息 */
export const updatePackage = (params: PackageInfo) => ajaxApi('post', alphaUrl + '/pkg/update', params);
