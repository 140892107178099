
import { Component, Vue, Prop, Model } from 'vue-property-decorator';

@Component({
  name: 'CheckBox',
})
export default class extends Vue {
  /** 是否已选中 */
  @Model('change', { type: Boolean, default: false }) checked!: boolean;
  /** 是否禁用 */
  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  get getClass() {
    return {
      checked: this.checked,
      disabled: this.disabled,
    };
  }

  /** 点击 */
  onClick() {
    // console.log('onClick---');

    this.$emit('change', !this.checked);
  }
}
