
import { Component, Vue, Model } from 'vue-property-decorator'
import { getJobBaseAll } from '@/api/jobs'
import { toOption, CascaderOption } from '@/utils/common'

@Component({
  name: 'JobBaseSelect',
})
export default class extends Vue {
  /** 选中的值 */
  @Model('change', { type: Array, default: () => [] }) value!: string[]

  get values() {
    return this.value
  }

  /** 选项 */
  options = [] as CascaderOption[]
  // props = {
  //   lazy: true,
  //   lazyLoad(node: any, resolve: any) {
  //     getJobBase(node.value).then(ls => {
  //       resolve(
  //         ls.map(i => ({
  //           value: i.classifyId,
  //           label: i.classifyName,
  //           leaf: node.level >= 2,
  //         }))
  //       );
  //     });
  //   },
  // }

  /** 点击 */
  onChange(val: []) {
    this.$emit('change', val)
  }

  created() {
    getJobBaseAll().then(ls => {
      this.options = toOption(ls, { value: 'classifyId', label: 'classifyName' })
    })
  }
}
