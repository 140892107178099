
import { Component, Vue, Model } from 'vue-property-decorator';
import { OptionModule } from '@/store/modules';

@Component({
  name: 'AcctSelect',
})
export default class extends Vue {
  /** 选中的值 */
  @Model('change', { type: String, default: '' }) value!: string;

  get mValue() {
    return this.value;
  }

  /** 选项 */
  get options() {
    return OptionModule.acctList.map(i => {
      const { name, phone, email } = i._origin || {};
      return {
        ...i,
        text: [name, phone, email].join(' / '),
      };
    });
  }

  /** 点击 */
  onChange(val: string) {
    this.$emit('change', val);
  }
}
