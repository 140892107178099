import { isEmail, isEmpty } from '@panstar/common'

/** 可校验多个邮箱 */
export const validateEmails = (rule: () => {}, val: string, callback: (err?: Error) => {}) => {
  val = val || ''
  if (isEmpty(val) || val.split(',').every(isEmail)) {
    callback()
  } else {
    callback(new Error('请输入有效的邮箱'))
  }
}
